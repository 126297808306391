@import url("https://fonts.googleapis.com/css?family=Didact+Gothic");
@font-face {
  font-family: 'cocogoose';
  src: url("../fonts/Cocogoose.otf") format("opentype");
  font-weight: 400;
  font-style: normal; }

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Didact Gothic', sans-serif; }

.hideMobile {
  display: block !important; }

.showMobile {
  display: none !important; }

.main-btn {
  background: white;
  line-height: 60px;
  font-size: 32px;
  text-decoration: none;
  width: 300px;
  margin: 0 auto;
  display: block;
  text-align: center;
  color: #4A4A4A;
  text-transform: uppercase;
  font-family: 'cocogoose', sans-serif; }

#home section#banner {
  min-height: 80vh;
  background: url("../img/banner.jpg") no-repeat scroll 50% 70%/cover;
  position: relative;
  z-index: 1; }

#home section#projects {
  background: white;
  position: relative;
  z-index: 2; }
  #home section#projects article:first-child #Logo {
    display: block;
    margin: 0 auto;
    background: url("../img/logo.png") no-repeat scroll center/contain;
    width: 273px;
    height: 186px;
    -moz-transform: translate(0, -90px);
    -o-transform: translate(0, -90px);
    -ms-transform: translate(0, -90px);
    -webkit-transform: translate(0, -90px);
    transform: translate(0, -90px); }
  #home section#projects article:first-child blockquote {
    width: 600px;
    margin: 0 auto;
    font-size: 26px;
    line-height: 40px;
    color: #9B9B9B;
    position: relative;
    -moz-transform: translate(0, -40px);
    -o-transform: translate(0, -40px);
    -ms-transform: translate(0, -40px);
    -webkit-transform: translate(0, -40px);
    transform: translate(0, -40px); }
    #home section#projects article:first-child blockquote:before {
      content: "";
      width: 48px;
      height: 39px;
      position: absolute;
      top: -39px;
      left: -60px;
      background: url("../img/quote.png") no-repeat scroll center/contain; }
    #home section#projects article:first-child blockquote cite {
      display: block;
      float: right;
      margin: 25px 0;
      width: 200px;
      height: 59px;
      background: url("../img/signature.jpg") no-repeat scroll center/contain; }
    #home section#projects article:first-child blockquote a {
      text-decoration: none;
      color: black; }
  #home section#projects article:last-child {
    box-sizing: border-box;
    padding: 50px;
    margin: 0; }
    #home section#projects article:last-child:before {
      content: "";
      display: block;
      clear: both;
      height: 1px; }
    #home section#projects article:last-child a {
      box-sizing: border-box;
      width: 50%;
      display: inline-block;
      position: relative; }
      #home section#projects article:last-child a.hideMobile {
        display: inline-block !important; }
      #home section#projects article:last-child a img {
        max-width: 100%;
        margin-bottom: -5px;
        margin-left: -1px; }
      #home section#projects article:last-child a:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;
        opacity: 0;
        -moz-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
      #home section#projects article:last-child a:after {
        content: "";
        display: block;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        width: 100%;
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
      #home section#projects article:last-child a:hover:before {
        cursor: pointer;
        opacity: 0.1;
        background: white;
        -moz-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1); }

footer {
  min-height: 100vh;
  background: #4A4A4A;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  footer:before {
    content: "";
    background: black;
    filter: blur(5px);
    border-radius: 50%;
    height: 30px;
    width: 100%;
    position: absolute;
    top: -20px;
    left: 0;
    opacity: 0.5; }
  footer section {
    width: 400px;
    margin: 0 auto;
    position: relative;
    transform: scale(0.8); }
    footer section:before {
      content: "";
      width: 1px;
      height: 250px;
      position: absolute;
      bottom: 125px;
      left: 50%;
      display: block;
      background: #C6C6C6; }
    footer section .price {
      font-family: 'cocogoose', sans-serif;
      font-size: 72px;
      text-align: center;
      color: #92AD8D;
      letter-spacing: 1px;
      margin: 0;
      position: relative; }
      footer section .price span {
        font-size: 18px;
        color: #767676;
        font-family: 'Didact Gothic', sans-serif;
        text-transform: uppercase;
        vertical-align: middle; }
      footer section .price:before {
        content: "";
        width: 100%;
        height: 1px;
        background: #C6C6C6;
        display: block;
        position: absolute;
        bottom: -40px;
        left: 0;
        z-index: 1; }
    footer section .or {
      display: block;
      text-align: center;
      color: white;
      width: 75px;
      height: 75px;
      line-height: 75px;
      margin: 0 auto;
      border-radius: 50%;
      text-transform: uppercase;
      border: 1px solid #C6C6C6;
      position: relative;
      z-index: 2;
      background: #4A4A4A; }
    footer section article {
      flex-direction: row;
      margin-bottom: 15px; }
      footer section article p {
        display: inline-block;
        width: 50%;
        box-sizing: border-box;
        padding: 0 50px;
        font-size: 36px;
        margin: 0;
        vertical-align: top; }
        footer section article p.hours {
          text-align: right;
          color: white;
          font-family: 'cocogoose', sans-serif; }
          footer section article p.hours span {
            font-size: 24px;
            color: #9B9B9B;
            margin-left: 5px;
            text-transform: uppercase;
            letter-spacing: 1px; }
        footer section article p.price {
          font-family: 'cocogoose', sans-serif;
          font-size: 36px;
          vertical-align: middle;
          text-align: left;
          letter-spacing: 1px; }
          footer section article p.price span {
            font-size: 18px;
            color: #767676;
            font-family: 'Didact Gothic', sans-serif;
            vertical-align: middle;
            margin-left: 5px; }
          footer section article p.price:before {
            display: none; }
    footer section .social {
      margin-top: 100px;
      font-size: 24px;
      letter-spacing: 2px;
      text-align: center; }
      footer section .social a {
        margin: 0 10px;
        display: inline-block;
        border-radius: 50%;
        -moz-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
        footer section .social a img {
          color: white;
          width: 40px;
          height: 40px;
          vertical-align: middle; }
        footer section .social a:hover {
          background: #3d3d3d;
          -moz-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1); }

@media (max-width: 768px) {
  .hideMobile {
    display: none !important; }
  .showMobile {
    display: block !important; }
  #home section#projects article:first-child blockquote {
    width: 100%;
    box-sizing: border-box;
    padding: 0 100px;
    font-size: 32px;
    line-height: 50px; }
    #home section#projects article:first-child blockquote:before {
      left: 45px; }
    #home section#projects article:first-child blockquote cite {
      margin: 25px 0 0 375px; }
  #home section#projects article a:after {
    background-size: 50% !important; }
  #home section#projects article:last-child {
    margin-bottom: 0; }
    #home section#projects article:last-child a.hideMobile {
      display: none !important; }
  footer {
    box-sizing: border-box;
    padding: 100px 0; } }

@media (max-width: 480px) {
  #home section#banner {
    min-height: 50vh;
    background: url("../img/banner.jpg") no-repeat scroll bottom/cover; }
  #home section#projects article {
    transform: translate(0, 0); }
    #home section#projects article:first-child #Logo {
      width: 50%; }
    #home section#projects article:first-child blockquote {
      padding: 0 25px 0 50px;
      font-size: 25px;
      line-height: 36px; }
      #home section#projects article:first-child blockquote:before {
        left: 15px;
        top: -50px; }
      #home section#projects article:first-child blockquote cite {
        margin: 25px auto 0;
        float: none;
        width: 175px;
        -moz-transform: translate(-15px, 0);
        -o-transform: translate(-15px, 0);
        -ms-transform: translate(-15px, 0);
        -webkit-transform: translate(-15px, 0);
        transform: translate(-15px, 0); }
      #home section#projects article:first-child blockquote:after {
        content: "";
        clear: both;
        display: block; }
    #home section#projects article:last-child {
      padding: 0;
      margin: 50px 0 0 0; }
      #home section#projects article:last-child a {
        min-height: auto;
        width: 100%;
        -moz-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0); }
  footer {
    padding: 0; }
    footer section {
      width: 100%;
      box-sizing: border-box;
      padding: 0 25px; }
      footer section .price span {
        font-size: 14px; }
      footer section article p {
        padding: 0 25px; }
        footer section article p.price span {
          font-size: 14px; }
      footer section .main-btn {
        margin-top: 50px; } }
